import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    mainLayoutType: themeConfig.mainLayoutType || 'vertical',
    navbarSearchAndPinList: navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: navbarSearchAndPinList['pages'].data.filter(
        (page) => page.is_bookmarked
    ),
    theme: themeConfig.theme || 'light',
    themePrimaryColor: colors.primary,
    windowWidth: null,

    // authentication
    isAuthenticated: false,
    loginError: null,
    /*
    tokens: {
        access: localStorage.getItem('accessToken'),
        refresh: localStorage.getItem('refreshToken'),
    },
    */
    tokens: {
        access: null,
        refresh: null,
    },
    endpoints: {
        baseUrl:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/broker/api/v2/'
                : 'http://127.0.0.1:8000/broker/api/v2/',
        obtainJWT:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/broker/api/v2/token/'
                : 'http://127.0.0.1:8000/broker/api/v2/token/',
        refreshJWT:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/broker/api/v2/token/refresh/'
                : 'http://127.0.0.1:8000/broker/api/v2/token/refresh/',
        obtainAccessCode:
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk/broker/api/v2/verify_access_code'
                : 'http://127.0.0.1:8000/broker/api/v2/verify_access_code',
    },
    accessCode: null,
    accessCodeError: null,
    prices: null,

    // app logic quick

    user: null,
    quickSendHistory: [],
    initialHandoverUser: null,

    // app logic main

    quickCodesOverview: [],
    handoverAccountsOverview: [],
    leadsOverview: [],
    loginTabIndex: 0,
    broker: null,

    // sign up form
    signUpForm: {
        company_name: null,
        address_line_1: null,
        address_line_2: null,
        post_town: null,    
        post_code: null,
        country: null,
        logo: null,
        email: null,
        contact_name: null,
        contact_number: null,
        plan: null,
        text_from: null,
        code_prefix: null,
        auto_send: true,
        username: null,
        password1: null,
        password2: null,
        terms_and_conditions: false,
        access_emails: [],        
    },
    signUpFormErrors: {
        company_name: null,
        address_line_1: null,
        address_line_2: null,
        post_town: null,    
        post_code: null,
        country: null,
        logo: null,
        email: null,
        contact_name: null,
        contact_number: null,
        plan: null,
        text_from: null,
        code_prefix: null,
        auto_send: true,
        username: null,
        password1: null,
        password2: null,
        terms_and_conditions: false,       
    },
    signUpBillingForm: {
        billingEmail: null,
        billingCompanyName: null,
        billingAddressLine1: null,
        billingTownCity: null,
        billingPostCode: null,
        billingCountry: 'GB',
        billingAccountNumber: null,
        billingSortCode: null,
    },
    signUpBillingErrors: {
        billingEmail: null,
        billingCompanyName: null,
        billingAddressLine1: null,
        billingTownCity: null,
        billingPostCode: null,
        billingCountry: null,
        billingAccountNumber: null,
        billingSortCode: null,
    },
    selectedPlanDetails: null,
    mandate: null,
    bankAccount: null
}

export default state

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        accessCode: state.accessCode,
        quickSendHistory: state.quickSendHistory,
        initialHandoverUser: state.initialHandoverUser,
        signUpForm: state.signUpForm,
        selectedPlanDetails: state.selectedPlanDetails,
        broker: state.broker,
        signUpBillingForm: state.signUpBillingForm,
        bankAccount: state.bankAccount,
        mandate: state.mandate,
        tokens: state.tokens
    }),
})

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    strict: process.env.NODE_ENV !== 'production',
    plugins: [vuexLocal.plugin],
})

export default {
    pages: {
        key: 'title',
        data: [
            { title: 'Home', url: '/', icon: 'HomeIcon', is_bookmarked: false },
            {
                title: 'Pipeline',
                url: '/wills/pipeline',
                icon: 'ActivityIcon',
                is_bookmarked: false,
            },
            {
                title: 'Wills',
                url: '/wills/all',
                icon: 'FileIcon',
                is_bookmarked: false,
            },
            {
                title: 'Will to check',
                url: '/wills/pipeline/to_check',
                icon: 'FileIcon',
                is_bookmarked: false,
            },
            {
                title: 'Will to print',
                url: '/wills/pipeline/to_print',
                icon: 'FileIcon',
                is_bookmarked: false,
            },
            {
                title: 'Will to contact',
                url: '/wills/pipeline/to_contact',
                icon: 'FileIcon',
                is_bookmarked: false,
            },
            {
                title: 'Will to approve',
                url: '/wills/pipeline/to_approve',
                icon: 'FileIcon',
                is_bookmarked: false,
            },
            {
                title: 'Users',
                url: '/users/all',
                icon: 'UserIcon',
                is_bookmarked: false,
            },
            {
                title: 'Users Staff',
                url: '/users/staff',
                icon: 'UserIcon',
                is_bookmarked: false,
            },
            {
                title: 'Advice',
                url: '/advice',
                icon: 'MessageSquareIcon',
                is_bookmarked: false,
            },
            {
                title: 'Settings',
                url: '/settings',
                icon: 'SettingsIcon',
                is_bookmarked: false,
            },
        ],
    },
}

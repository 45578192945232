import Vue from 'vue'
import { http } from '@/services'

const mutations = {
    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu

    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
        state.isVerticalNavMenuActive = value
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val
    },
    UPDATE_MAIN_LAYOUT_TYPE(state, val) {
        state.mainLayoutType = val
    },
    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
        state.verticalNavMenuItemsMin = val
    },
    UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
        state.verticalNavMenuWidth = width
    },

    // VxAutoSuggest

    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList['pages'].data.findIndex(
            (item) => item.url == payload.url
        )

        // update the main list
        state.navbarSearchAndPinList['pages'].data[index].is_bookmarked =
            payload.val

        // if val is true add it to starred else remove
        if (payload.val) {
            state.starredPages.push(
                state.navbarSearchAndPinList['pages'].data[index]
            )
        } else {
            // find item index from starred pages
            const index = state.starredPages.findIndex(
                (item) => item.url == payload.url
            )

            // remove item using index
            state.starredPages.splice(index, 1)
        }
    },

    UPDATE_SEARCH_RESULTS(state, payload) {
        Vue.set(state.navbarSearchAndPinList, payload.value, payload.data)
    },

    // Navbar-Vertical

    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10)
        state.starredPages = list.concat(starredPagesMore)
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10]
        const starredPagesLimited = state.starredPages.slice(0, 10)
        state.starredPages = starredPagesLimited.concat(list)

        state.starredPages.slice(0, 10).map((i) => {
            if (list.indexOf(i) > -1) downToUp = true
        })

        if (!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited)
        }
    },

    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val
    },
    UPDATE_THEME(state, val) {
        state.theme = val
    },
    UPDATE_WINDOW_WIDTH(state, width) {
        state.windowWidth = width
    },
    UPDATE_WINDOW_SCROLL_Y(state, val) {
        state.scrollY = val
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        // Get Data localStorage
        let userInfo =
            JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

        for (const property of Object.keys(payload)) {
            if (payload[property] != null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    user(state, { authUser, isAuthenticated }) {
        Vue.set(state, 'user', authUser)
        Vue.set(state, 'isAuthenticated', isAuthenticated)
    },

    // authentication

    loginError(state, message) {
        state.loginError = message
    },
    accessToken(state, newToken) {
        localStorage.setItem('accessToken', newToken)
        state.tokens.access = newToken
    },
    refreshToken(state, newToken) {
        localStorage.setItem('refreshToken', newToken)
        state.tokens.refresh = newToken
    },
    removeTokens(state) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        http.defaults.headers.Authorization = null
        state.tokens.access = null
        state.tokens.refresh = null
    },

    // app logic common

    clearInitialise(state) {
        state.user = null
        state.accessCode = null
    },
    prices(state, data) {
        state.prices = data
    },

    // app logic quick

    accessCode(state, data) {
        state.accessCode = data
    },
    accessCodeError(state, data) {
        state.accessCodeError = data
    },

    quickSendHistoryAdd(state, data) {
        state.quickSendHistory.unshift(data)
    },
    quickSendHistoryClear(state) {
        state.quickSendHistory = []
    },
    initialHandoverUser(state, data) {
        state.initialHandoverUser = data
    },

    // app logic main

    quickCodesOverview(state, data) {
        state.quickCodesOverview = data
    },
    handoverAccountsOverview(state, data) {
        state.handoverAccountsOverview = data
    },
    leadsOverview(state, data) {
        state.leadsOverview = data
    },
    loginTabIndex(state, data) {
        state.loginTabIndex = data
    },
    broker(state, data) {
        state.broker = data
    },

    // sign up

    signUpForm(state, data) {
        Object.keys(data).forEach((key, index) => {
            state.signUpForm[key] = data[key]
        })
    },
    signUpFormErrors(state, data) {
        Object.keys(data).forEach((key, index) => {
            state.signUpFormErrors[key] = data[key][0]
        })
    },
    signUpFormClear(state) {
        state.signUpForm.company_name = null
        state.signUpForm.address_line_1 = null
        state.signUpForm.address_line_2 = null
        state.signUpForm.post_town = null
        state.signUpForm.post_code = null
        state.signUpForm.country = null
        state.signUpForm.logo = null
        state.signUpForm.email = null
        state.signUpForm.contact_name = null
        state.signUpForm.contact_number = null
        state.signUpForm.plan = null
        state.signUpForm.text_from = null
        state.signUpForm.code_prefix = null
        state.signUpForm.auto_send = true
        state.signUpForm.username = null
        state.signUpForm.password1 = null
        state.signUpForm.password2 = null
        state.signUpForm.terms_and_conditions = false
        state.signUpForm.access_emails = []
    },
    signUpFormErrorsClear(state) {
        state.signUpFormErrors.company_name = null
        state.signUpFormErrors.address_line_1 = null
        state.signUpFormErrors.address_line_2 = null
        state.signUpFormErrors.post_town = null
        state.signUpFormErrors.post_code = null
        state.signUpFormErrors.country = null
        state.signUpFormErrors.logo = null
        state.signUpFormErrors.email = null
        state.signUpFormErrors.contact_name = null
        state.signUpFormErrors.contact_number = null
        state.signUpFormErrors.plan = null
        state.signUpFormErrors.text_from = null
        state.signUpFormErrors.code_prefix = null
        state.signUpFormErrors.auto_send = true
        state.signUpFormErrors.primis_id = null
        state.signUpFormErrors.username = null
        state.signUpFormErrors.password1 = null
        state.signUpFormErrors.password2 = null
        state.signUpFormErrors.terms_and_conditions = null
    },
    signUpBillingForm(state, data) {
        Object.keys(data).forEach((key, index) => {
            state.signUpBillingForm[key] = data[key]
        })
    },
    signUpBillingErrors(state, data) {
        Object.keys(data).forEach((key, index) => {
            state.signUpBillingErrors[key] = data[key][0]
        })
    },
    signUpBillingFormClear(state) {
        state.signUpBillingForm.billingEmail = null
        state.signUpBillingForm.billingCompanyName = null
        state.signUpBillingForm.billingAddressLine1 = null
        state.signUpBillingForm.billingTownCity = null
        state.signUpBillingForm.billingPostCode = null
        state.signUpBillingForm.billingCountry = 'GB'
        state.signUpBillingForm.billingAccountNumber = null
        state.signUpBillingForm.billingSortCode = null
    },
    signUpBillingErrorsClear(state) {
        state.signUpBillingErrors.billingEmail = null
        state.signUpBillingErrors.billingCompanyName = null
        state.signUpBillingErrors.billingAddressLine1 = null
        state.signUpBillingErrors.billingTownCity = null
        state.signUpBillingErrors.billingPostCode = null
        state.signUpBillingErrors.billingCountry = null
        state.signUpBillingErrors.billingAccountNumber = null
        state.signUpBillingErrors.billingSortCode = null
    },
    selectedPlanDetails(state, data) {
        state.selectedPlanDetails = data
    },
    addAccessEmail(state, data) {
        state.signUpForm.access_emails.push(data)
    },
    removeAccessEmail(state, data) {
        state.signUpForm.access_emails.splice(data, 1)
    },
    mandate(state, data) {
        state.mandate = data
    },
    bankAccount(state, data) {
        state.bankAccount = data
    },
    brokerRemoveGoCardlessCustomer(state) {
        state.broker.go_card_less_customer_id = null
    }
}

export default mutations

<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
========================================================================================== -->

<template>
    <div id="app" :class="vueAppClasses">
        <router-view @setAppClasses="setAppClasses" />
    </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'

export default {
    data() {
        return {
            vueAppClasses: [],
        }
    },
    watch: {
        '$store.state.theme'(val) {
            this.toggleClassInBody(val)
        },
        '$vs.rtl'(val) {
            document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
        },
    },
    methods: {
        toggleClassInBody(className) {
            if (className == 'dark') {
                if (document.body.className.match('theme-semi-dark'))
                    document.body.classList.remove('theme-semi-dark')
                document.body.classList.add('theme-dark')
            } else if (className == 'semi-dark') {
                if (document.body.className.match('theme-dark'))
                    document.body.classList.remove('theme-dark')
                document.body.classList.add('theme-semi-dark')
            } else {
                if (document.body.className.match('theme-dark'))
                    document.body.classList.remove('theme-dark')
                if (document.body.className.match('theme-semi-dark'))
                    document.body.classList.remove('theme-semi-dark')
            }
        },
        setAppClasses(classesStr) {
            this.vueAppClasses.push(classesStr)
        },
        handleWindowResize() {
            this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

            // Set --vh property
            document.documentElement.style.setProperty(
                '--vh',
                `${window.innerHeight * 0.01}px`
            )
        },
        handleScroll() {
            this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
        },
    },
    mounted() {
        global.vm = this
        this.toggleClassInBody(themeConfig.theme)
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        // this.$store.dispatch('inspectToken')
    },
    async created() {
        let dir = this.$vs.rtl ? 'rtl' : 'ltr'
        document.documentElement.setAttribute('dir', dir)

        window.addEventListener('resize', this.handleWindowResize)
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss">
.showOverflow {
    overflow: visible;
}
.link {
    text-decoration: underline;
    cursor: pointer;
}
.pointer {
    cursor: pointer;
}

.text-pallet1 {
    color: #ff8b87;
}
.text-pallet2 {
    color: #6ebeff;
}
.text-pallet3 {
    color: #f8ff54;
}
.text-pallet4 {
    color: #aeb344;
}
.email-head {
    position: fixed !important;
    right: 20px;
    top: 200px;
}
.sms-head {
    position: fixed !important;
    right: 20px;
    top: 290px;
}
.paginator-container {
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}
.vs-input-white .vs-input--label {
    color: #ffffff !important;
}
.vs-input-white .vs-input--input {
    color: black;
}
#data-list-list-view {
    .vs-con-table {
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;

            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
                td {
                    padding: 20px;
                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }
                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }
                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            th.td-check {
                padding: 0 15px !important;
            }
            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
.flat .vs-con-table {
    .vs-table {
        border-collapse: collapse !important;
        border-spacing: 0 !important;
    }
    .vs-con-tbody {
        background: white !important;
        border: none !important;
    }
    tr {
        box-shadow: none !important;
    }
    td {
        padding: 6px 14px !important;
    }
}

.white {
    .vs-input--label {
        color: white !important;
    }
    &.vs-input-primary {
        .vs-input--input {
            color: #203a4e;
            border-color: white !important;
        }
        .vs-con-input {
            .vs-input--icon {
                color: rgba(var(--vs-primary), 1);
            }
        }
    }
    &.vs-input-success {
        .vs-input--input {
            color: #346651;
            border-color: white !important;
        }
        .vs-con-input {
            .vs-input--icon {
                color: rgba(var(--vs-success), 1);
            }
        }
    }
    &.vs-input-secondary {
        .vs-input--input {
            color: #7a3745;
            border-color: white !important;
        }
        .vs-con-input {
            .vs-input--icon {
                color: rgba(var(--vs-secondary), 1);
            }
        }
    }
    .span-text-validation-danger {
        font-size: 0.8rem;
        font-weight: bold;
    }
}
.primary {
    .vs-input--label {
        color: var(--vs-primary) !important;
    }
    .vs-input--input {
        border-color: var(--vs-primary) !important;
    }
    &.vs-textarea-primary {
        border-color: var(--vs-primary) !important;
    }
    .vs__dropdown-toggle {
        border-color: var(--vs-primary) !important;
    }
    .vs-input--icon {
        color: rgba(var(--vs-primary), 1);
    }
    .v-select .vs__dropdown-toggle .vs__search {
        color: var(--vs-primary) !important;
    }
}

.vc-popover-content-wrapper {
    z-index: 500 !important;
}
</style>

import state from "./state"

const getters = {
    windowBreakPoint: (state) => {
        if (state.windowWidth >= 1200) return 'xl'
        else if (state.windowWidth >= 992) return 'lg'
        else if (state.windowWidth >= 768) return 'md'
        else if (state.windowWidth >= 576) return 'sm'
        else return 'xs'
    },
    endpoints: (state) => {
        return state.endpoints
    },
    tokens: (state) => {
        return state.tokens
    },
    loginError: (state) => {
        return state.loginError
    },

    accessCodeError: (state) => {
        return state.accessCodeError
    },
    prices: (state) => {
        return state.prices
    },

    // app login quick

    accessCode: (state) => {
        return state.accessCode
    },

    quickSendHistory: (state) => {
        return state.quickSendHistory
    },
    initialHandoverUser: (state) => {
        return state.initialHandoverUser
    },

    // app login main

    user: (state) => {
        return state.user
    },

    quickCodesOverview: (state) => {
        return state.quickCodesOverview
    },
    handoverAccountsOverview: (state) => {
        return state.handoverAccountsOverview
    },
    leadsOverview: (state) => {
        return state.leadsOverview
    },
    loginTabIndex: (state) => {
        return state.loginTabIndex
    },
    broker: (state) => {
        return state.broker
    },  

    // sign up form

    signUpForm: (state) => {
        return state.signUpForm
    },
    signUpFormErrors: (state) => {
        return state.signUpFormErrors
    },
    signUpBillingForm: (state) => {
        return state.signUpBillingForm
    },
    signUpBillingErrors: (state) => {
        return state.signUpBillingErrors
    },
    selectedPlanDetails: (state) => {
        return state.selectedPlanDetails
    },
    mandate: (state) => {
        return state.mandate
    },
    bankAccount: (state) => {
        return state.bankAccount
    }

}

export default getters

import { http } from '@/services'

import jwt_decode from 'jwt-decode'
import router from '@/router'
import axios from 'axios'

const actions = {
    // Vertical NavMenu

    updateVerticalNavMenuWidth({ commit }, width) {
        commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest

    updateStarredPage({ commit }, payload) {
        commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar

    arrangeStarredPagesLimited({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
    toggleContentOverlay({ commit }) {
        commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
        commit('UPDATE_THEME', val)
    },

    // authentication
    async inspectToken({ dispatch, getters, commit }, noRedirect=false) {
        const accessCode = getters.accessCode
        const token = getters.tokens.access
        if (accessCode) {
            var from = router.history._startLocation
            var url = from.includes('/handover_created') ? from : '/quick_send'
            router.push(url).catch((error) => {
                console.log(error)
            })
        } else if (token) {
            if (
                http.defaults &&
                http.defaults.headers.Authorization !== `JWT ${token}`
            ) {
                dispatch('setHttpHeaders')
            }

            const decoded = jwt_decode(token)
            const exp = decoded.exp
            const now = Date.now() / 1000

            if (exp - now < 300) {
                // less than 5 minutes to go 300
                let response = dispatch('refreshToken')
                return response
            } else if (exp - now < 86400) {
                // 1 day
                // DO NOTHING, DO NOT REFRESH
                if (!getters.user) {
                    console.log('no user')
                    try {
                        dispatch('userFetch')
                        if (
                            router.currentRoute &&
                            router.currentRoute.name !== 'Overview'
                        )
                        if (!noRedirect) {
                            router.push('/home').catch((error) => {
                                console.log(error)
                            })
                        }
                         
                    } catch (error) {
                        throw error
                    }
                } else {
                    console.log('user')
                    if (
                        router.currentRoute &&
                        router.currentRoute.name !== 'Overview'
                    )
                    if (!noRedirect) {
                        router.push('/home').catch((error) => {
                            console.log(error)
                        })
                    }
                }
            } else {
                dispatch('logout')
            }
        } else {
            var from_url = router.history._startLocation
            if (from_url.includes('/register')) dispatch('logout', true)
            else dispatch('logout')
        }
        return Promise.resolve(true)
    },
    setHttpHeaders({ getters }) {
        console.log('set headers')
        const baseHTTP = {
            baseUrl: getters.endpoints.baseUrl,
            headers: {
                Authorization: `JWT ${getters.tokens.access}`,
                'Content-Type': 'application/json',
            },
        }
        http.defaults.headers = baseHTTP.headers
    },
    logout({ commit }, noReRoute) {
        commit('accessCode', null)
        commit('removeTokens')
        commit('clearInitialise')
        commit('quickSendHistoryClear')
        if (!noReRoute) {
            router.push('/login').catch((error) => {
                console.log(error)
            })
        }
    },
    async _setTokens({ commit, dispatch }, data) {
        console.log('set tokens')
        commit('loginError', null)
        commit('accessToken', data.access)
        commit('refreshToken', data.refresh)
        dispatch('setHttpHeaders')
        if (!data.noRedirect) {
            router.push('/home').catch((error) => {
                console.log(error)
            })
        }
        dispatch('userFetch')
        return Promise.resolve(true)
    },
    async obtainToken({ commit, getters, dispatch }, data) {
        const payload = {
            email: data.email,
            password: data.password,
        }
        axios.defaults.withCredentials = true

        return axios
            .post(getters.endpoints.obtainJWT, payload)
            .then((response) => {
                if (data.noRedirect) {
                    response.data.noRedirect = true
                    dispatch('_setTokens', response.data)
                } else {
                    dispatch('_setTokens', response.data)
                }
            })
            .catch((error) => {
                commit('loginError', error.response.data)
                throw error
            })
    },
    async refreshToken({ commit, getters, dispatch }) {
        const decoded = jwt_decode(getters.tokens.refresh)
        const exp = decoded.exp
        const now = Date.now() / 1000

        if (!getters.tokens.refresh || exp - now <= 0) {
            dispatch('logout')
            return
        }

        const payload = {
            refresh: getters.tokens.refresh,
        }

        return axios
            .post(getters.endpoints.refreshJWT, payload)
            .then((response) => {
                commit('accessToken', response.data.access)
                dispatch('setHttpHeaders')
                try {
                    dispatch('userFetch')
                    router.push('/home').catch((error) => {
                        console.log(error)
                    })
                } catch (error) {
                    throw error
                }
                return Promise.resolve(true)
            })
            .catch((error) => {
                console.log('error in refreshing call', error)
                return Promise.resolve(false)
            })
    },
    async obtainAccessCode({ commit, getters, dispatch }, code) {
        const payload = {
            code: code,
        }
        axios.defaults.withCredentials = true

        return axios
            .post(getters.endpoints.obtainAccessCode, payload)
            .then((response) => {
                dispatch('_setAccessCode', response.data)
            })
            .catch((error) => {
                commit('accessCodeError', error.response.data)
                throw error
            })
    },
    async _setAccessCode({ commit, dispatch }, data) {
        commit('accessCodeError', null)
        commit('accessCode', data)
        router.push('/quick_send').catch((error) => {
            console.log(error)
        })
        return Promise.resolve(true)
    },

    // app logic

    initialise({ dispatch, getters }) {
        dispatch('quickCodesOverviewFetch')
        dispatch('handoverAccountsOverviewFetch')
        dispatch('leadsOverviewFetch')
        dispatch('pricesFetch')
    },
    userFetch({ commit, dispatch }) {
        console.log('fetch user')
        http.get('user_current')
            .then((response) => {
                commit('user', {
                    authUser: response.data,
                    isAuthenticated: true,
                })
                dispatch('initialise')
            })
            .catch((error) => {
                console.log(error)
            })
    },
    quickCodesOverviewFetch({ commit }) {
        http.get('quick_code_list', { params: { items_per_page: 3 } })
            .then((response) => {
                commit('quickCodesOverview', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    handoverAccountsOverviewFetch({ commit }) {
        http.get('handover_accounts', { params: { items_per_page: 3 } })
            .then((response) => {
                commit('handoverAccountsOverview', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    leadsOverviewFetch({ commit }) {
        http.get('advice_list', { params: { items_per_page: 3 } })
            .then((response) => {
                commit('leadsOverview', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },

    pricesFetch({ commit }) {
        var url =
            process.env.NODE_ENV === 'production'
                ? 'https://www.mylastwill.co.uk'
                : 'http://127.0.0.1:8000'
        http.get(url + '/wills/api/prices')
            .then((response) => {
                commit('prices', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    brokerFetch({commit}) {
        http.get('account_settings' ).then((response) => {
            commit('broker', response.data)
        }).catch((error) => {
        })
    }
}

export default actions

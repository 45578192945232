import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '',
            redirect: '/login',
        },
        {
            path: '',
            component: () => import('../layouts/main/Main.vue'),
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('@/views/main/Home/index.vue'),
                },
                {
                    path: '/quick_codes',
                    name: 'quickCodes',
                    component: () =>
                        import('@/views/main/QuickCodes/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Access Codes',
                                url: '/quick_codes',
                                active: true,
                            },
                        ],
                        pageTitle: 'Access Codes',
                    },
                },
                {
                    path: '/send_single',
                    name: 'sendSingle',
                    component: () =>
                        import('@/views/main/SendSingle/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Send Single',
                                url: '/send_single',
                                active: true,
                            },
                        ],
                        pageTitle: 'Send Single',
                    },
                },
                {
                    path: '/send_batch',
                    name: 'sendBatch',
                    component: () => import('@/views/main/SendBatch/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Send Batch',
                                url: '/send_batch',
                                active: true,
                            },
                        ],
                        pageTitle: 'Send Batch',
                    },
                },
                {
                    path: '/handover',
                    name: 'handover',
                    component: () => import('@/views/main/Handover/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'handover',
                                url: '/handover',
                                active: true,
                            },
                        ],
                        pageTitle: 'Handover',
                    },
                },
                {
                    path: '/handover/handover_created/:email',
                    name: 'handover',
                    component: () => import('@/views/main/Handover/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'handover',
                                url: '/handover',
                                active: true,
                            },
                        ],
                        pageTitle: 'Handover',
                    },
                },
                {
                    path: '/referral',
                    name: 'referral',
                    component: () => import('@/views/main/Refer/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'referral',
                                url: '/referral',
                                active: true,
                            },
                        ],
                        pageTitle: 'Referral',
                    },
                },
                {
                    path: '/clients',
                    name: 'clients',
                    component: () => import('@/views/main/Clients/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'clients',
                                url: '/clients',
                                active: true,
                            },
                        ],
                        pageTitle: 'Clients',
                    },
                },
                {
                    path: '/leads',
                    name: 'leads',
                    component: () =>
                        import('@/views/main/AdviceRequests/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'leads',
                                url: '/leads',
                                active: true,
                            },
                        ],
                        pageTitle: 'Leads',
                    },
                },
                {
                    path: '/finance/invoices',
                    name: 'invoices',
                    component: () =>
                        import('@/views/main/Finance/Invoices/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Finance',
                                url: '',
                                active: false,
                            },
                            {
                                title: 'Invoices',
                                url: '/finance/invoices',
                                active: true,
                            },
                        ],
                        pageTitle: 'Invoices',
                    },
                },
                {
                    path: '/finance/invoices/invoice/:pk',
                    name: 'invoice',
                    component: () =>
                        import(
                            '@/views/main/Finance/Invoices/Invoice/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Finance',
                                url: '',
                                active: false,
                            },
                            {
                                title: 'Invoices',
                                url: '/finance/invoices',
                                active: false,
                            },
                            {
                                title: 'Invoice',
                                url: '',
                                active: true,
                            },
                        ],
                        pageTitle: 'Invoice',
                    },
                },
                {
                    path: '/finance/commissions',
                    name: 'commissions',
                    component: () =>
                        import('@/views/main/Finance/Commissions/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'Finance',
                                url: '',
                                active: false,
                            },
                            {
                                title: 'Commissions',
                                url: '/invoices/commissions',
                                active: true,
                            },
                        ],
                        pageTitle: 'Commissions',
                    },
                },
                {
                    path: '/reports/completed_wills',
                    name: 'completedWills',
                    component: () =>
                        import('@/views/main/Reports/CompletedWills/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            { title: 'Reports', url: '' },
                            {
                                title: 'Completed Wills',
                                url: '/reports/completed_wills',
                                active: true,
                            },
                        ],
                        pageTitle: 'Completed Wills',
                    },
                },
                {
                    path: '/reports/send_logs',
                    name: 'sendLogs',
                    component: () =>
                        import('@/views/main/Reports/SendLogs/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            { title: 'Reports', url: '' },
                            {
                                title: 'Completed Wills',
                                url: '/reports/send_logs',
                                active: true,
                            },
                        ],
                        pageTitle: 'Send Logs',
                    },
                },
                {
                    path: '/reports/professional_executors',
                    name: 'professionalExecutors',
                    component: () =>
                        import(
                            '@/views/main/Reports/ProfessionalExecutors/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            { title: 'Reports', url: '' },
                            {
                                title: 'Professional Executors',
                                url: '/reports/professional_executors',
                                active: true,
                            },
                        ],
                        pageTitle: 'Professional Executors',
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('@/views/main/settings/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/home' },
                            {
                                title: 'settings',
                                url: '/settings',
                                active: true,
                            },
                        ],
                        pageTitle: 'Settings',
                    },
                },
            ],
        },
        {
            path: '/handover_created/:email',
            name: 'handoverCreated',
            redirect: store.getters.accessCode
                ? '/quick_send/handover_created/:email'
                : '/handover/handover_created/:email',
        },
        {
            path: '',
            component: () => import('../layouts/quick/Quick.vue'),
            children: [
                {
                    path: '/quick_send',
                    name: 'quick',
                    component: () => import('@/views/quick/Home/index.vue'),
                },
                {
                    path: '/quick_send/handover_created/:email',
                    name: 'handoverCreatedQuick',
                    component: () => import('@/views/quick/Home/index.vue'),
                },
            ],
        },
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'page-login',
                    component: () => import('@/views/Login.vue'),
                },
                {
                    path: '/login/:access/:refresh',
                    name: 'login-with-codes',
                    component: () => import('@/views/Login.vue'),
                },
                {
                    path: '/register',
                    name: 'page-login',
                    component: () => import('@/views/Login.vue'),
                },
                {
                    path: '/register/access/:code',
                    name: 'page-login',
                    component: () => import('@/views/Login.vue'),
                },
                {
                    path: '/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/Error404.vue'),
                },
                {
                    path: '/sign_up',
                    name: 'signUp',
                    component: () => import('@/views/signup/index'),
                },
            ],
        },

        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404',
        },
    ],
})

export default router
